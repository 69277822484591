import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { NavLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import keycloak from "../keycloak";
import ApiIcon from '@mui/icons-material/Api';
const Header = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1201,
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      }}
      key="header"
    >
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <ApiIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <NavLink
              to="/"
              style={{
                color: "white",
                display: "block",
                textDecoration: "none",
              }}
            >
              LLM DASHBOARD
            </NavLink>
          </Typography>

          {keycloak && keycloak.authenticated && (
            <Box sx={{ marginLeft: "auto" }}>
              <NavLink
                onClick={() => keycloak.logout()}
                style={{
                  color: "white",
                  display: "block",
                  textDecoration: "none",
                }}
              >
                <LogoutIcon />
              </NavLink>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
