// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .dashboard {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .app-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .MuiCard-root {
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .MuiCard-root:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .MuiTypography-h4 {
    font-weight: bold;
  }
   */

   .dashboard-background {
    background-color: #f0f0f0; /* Gray background */
    width: 100%;              /* Full viewport width */
    min-height: 100vh;         /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dashboard {
    padding: 0px;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;IAuBI;;GAED;IACC,yBAAyB,EAAE,oBAAoB;IAC/C,WAAW,eAAe,wBAAwB;IAClD,iBAAiB,UAAU,yBAAyB;IACpD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,cAAc;EAChB","sourcesContent":["/* .dashboard {\r\n    padding: 20px;\r\n    background-color: #f5f5f5;\r\n  }\r\n  \r\n  .app-cards {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .MuiCard-root {\r\n    transition: transform 0.3s, box-shadow 0.3s;\r\n  }\r\n  \r\n  .MuiCard-root:hover {\r\n    transform: scale(1.05);\r\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);\r\n  }\r\n  \r\n  .MuiTypography-h4 {\r\n    font-weight: bold;\r\n  }\r\n   */\r\n\r\n   .dashboard-background {\r\n    background-color: #f0f0f0; /* Gray background */\r\n    width: 100%;              /* Full viewport width */\r\n    min-height: 100vh;         /* Full viewport height */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .dashboard {\r\n    padding: 0px;\r\n    margin: 0 auto;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
